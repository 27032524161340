<template>
  <div style="padding: 1rem 1rem 0 1rem;">
    <b-row>
      <b-col cols="9">
        <b-row class="py-1">
          <b-col cols="4">
            <span>{{ $t("attribute.id") }}:</span>
          </b-col>
          <b-col>
            <span>{{ id }}</span>
          </b-col>
        </b-row>
        <b-row class="py-1">
          <b-col cols="4">
            <span>{{ $t("attribute.name") }}:</span>
          </b-col>
          <b-col>
            <span>{{ name }}</span>
          </b-col>
        </b-row>
        <b-row class="py-1">
          <b-col cols="4">
            <span>{{ $t("attribute.type") }}:</span>
          </b-col>
          <b-col>
            <span>{{ detail.type }}</span>
          </b-col>
        </b-row>
        <b-row class="py-1">
          <b-col cols="4">
            <span>{{ $t("attribute.code") }}:</span>
          </b-col>
          <b-col>
            <span>{{ code }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <div>
          <b-img style="width: 100%;height: 100%;" v-if="files.length && files[0].url" :src="files[0].url" @error="
						e => {
							e.target.src = defaultImage;
						}
					"></b-img>
        </div>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {getIndicator} from "@/utils/common";
import defaultImage from '@/assets/image.png';

export default {
  props: {
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      defaultImage: defaultImage,
    };
  },
  computed: {
    name() {
      if (this.detail["key"] == "fibre" || this.detail["key"] == "yarn") {
        return getIndicator(this.detail.business_index, "product_name");
      }
      return this.detail.name;
    },
    id() {
      if (['textile_bedware', 'textile_home_decor', 'textile_industrial', 'textile_kitchen'].includes(this.detail["key"])) {
        return this.detail['textile_id']
      }
      return this.detail[this.detail["key"] + "_id"];
    },
    code() {
      var value = "";
      const code = this.detail["code"];
      const code_2 = this.detail["code_2"];
      const code_ciq = this.detail["code_ciq"];
      const code_title = this.detail["code_title"];
      if (code) {
        value += code + "-";
      }
      if (code_2) {
        value += code_2 + "-";
      } else {
        value += "-";
      }
      if (code_ciq) {
        value += code_ciq;
      }
      if (code_title) {
        value += "  " + code_title;
      }
      return value;
    },
    files() {
      if (this.detail && this.detail.style) {
        return this.detail.style;
      }
      if (this.detail && this.detail.pictures) {
        return this.detail.pictures;
      }
      return [];
    },
  },
}
</script>
<style lang="scss">
:root {
  //--vz-body-font-size: 1rem;
}
</style>
