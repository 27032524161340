<template>
  <div>
    <div class="hand_chart position-relative">
      <div :id="handChartId" class="chart-box"></div>
      <div class="chart-options d-flex align-items-center">
        <div>{{$t('fabric.id')}}:</div>
        <div>
          <div class="btn-group" role="group">
            <button id="btnGroupVerticalDrop1" type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="padding: 0 3px;">
              {{ currentHandFabric.fabric_id }}
            </button>
            <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
              <span v-for="(hItem,hIndex) in handFabrics" :key="hIndex" class="dropdown-item" @click="setCurrentHandFabric(hItem)">{{hItem.fabric_id}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bars p-4 pt-0 ps-3">
      <div class="py-1">
        <table>
          <tr>
            <td>
              <div class="bar-title">{{ $t('radar_chart.roughness') }}：</div>
            </td>
            <td class="w-100 py-5">
              <feel-tip-bar bar-type="hand_roughness" :value="hand_feel_data.roughness"></feel-tip-bar>
            </td>
          </tr>
          <tr>
            <td>
              <div class="bar-title">{{ $t('radar_chart.softness') }}：</div>
            </td>
            <td class="w-100 py-5">
              <feel-tip-bar bar-type="hand_softness" :value="hand_feel_data.softness"></feel-tip-bar>
            </td>
          </tr>
          <tr>
            <td>
              <div class="bar-title">{{ $t('radar_chart.warmth') }}：</div>
            </td>
            <td class="w-100 py-5">
              <feel-tip-bar bar-type="hand_warmth" :value="hand_feel_data.warmth"></feel-tip-bar>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {radarChart} from "@/utils/chart";
import _ from "lodash";
import FeelTipBar from "@/components/feel-tip-bar.vue";

export default {
  components: {FeelTipBar},
  props: {
    detailForm: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      handChartId: `hand${+new Date()}`,
      hand_feel_star: {
        roughness: "",
        softness: "",
        warmth: "",
        total: "",
      },
      hand_feel_data: {
        roughness: "",
        softness: "",
        warmth: "",
        total: "",
      },
      hand_feel_score: {
        roughness: "",
        softness: "",
        warmth: "",
        total: "",
      },
      name: "",
      currentHandFabric: {},
      handFabrics: [],
    };
  },
  methods: {
    initChart() {
      this.hand_feel_chart();
    },
    hand_feel_chart() {
      let options = {
        data: [
          this.get_number_default(this.hand_feel_data.softness),
          this.get_number_default(this.hand_feel_data.roughness),
          this.get_number_default(this.hand_feel_data.warmth),
          this.get_number_default(this.hand_feel_data.total),
        ],
        ave: [
          this.get_number_default(this.hand_feel_score.softness),
          this.get_number_default(this.hand_feel_score.roughness),
          this.get_number_default(this.hand_feel_score.warmth),
          this.get_number_default(this.hand_feel_score.total),
        ],
        color: "#50C3E6",
        name: this.name,
        chartDom: document.querySelector(`#${this.handChartId}`),
      };
      radarChart(options, 'mobile');
    },
    number_fixed(data) {
      if (data == "") {
        return data;
      }
      return parseFloat(data.toFixed(2));
    },
    get_number_default(data) {
      if (data == "") {
        return 0;
      }
      return data;
    },
    // 获取当前面料的手感肤感评分
    getFabricScore() {
      this.hand_feel_star.roughness = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel_value.hand_smooth", "")
      );
      this.hand_feel_star.softness = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel_value.hand_stiff", "")
      );
      this.hand_feel_star.warmth = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel_value.hand_cool", "")
      );
      this.hand_feel_star.total = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel_value.hand_total", "")
      );

      this.hand_feel_data.roughness = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel.hand_smooth", 0)
      );
      this.hand_feel_data.softness = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel.hand_stiff", 0)
      );
      this.hand_feel_data.warmth = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel.hand_cool", 0)
      );
      this.hand_feel_data.total = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel.hand_total", 0)
      );

      this.hand_feel_score.roughness = this.number_fixed(
          _.get(this.currentHandFabric, "avg_score.hand_smooth", 0)
      );
      this.hand_feel_score.softness = this.number_fixed(
          _.get(this.currentHandFabric, "avg_score.hand_stiff", 0)
      );
      this.hand_feel_score.warmth = this.number_fixed(
          _.get(this.currentHandFabric, "avg_score.hand_cool", 0)
      );
      this.hand_feel_score.total = this.number_fixed(
          _.get(this.currentHandFabric, "avg_score.hand_total", 0)
      );

      this.$nextTick(() => {
        this.initChart();
      });
    },
    setCurrentHandFabric(item) {
      this.currentHandFabric = item;
      this.getFabricScore()
    },
  },
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          this.name = _.get(val, "name", "");
          if (this.type === 'fabric') {
            this.hand_feel_star.roughness = this.number_fixed(
              _.get(val, "hand_feel_garment_roughness", "")
            );
            this.hand_feel_star.softness = this.number_fixed(
              _.get(val, "hand_feel_garment_softness", "")
            );
            this.hand_feel_star.warmth = this.number_fixed(
              _.get(val, "hand_feel_garment_warmth", "")
            );
            this.hand_feel_star.total = this.number_fixed(
              _.get(val, "total_hand_feel", "")
            );

            this.hand_feel_data.roughness = this.number_fixed(
              _.get(val, "score.hfgr_normalized", 0)
            );
            this.hand_feel_data.softness = this.number_fixed(
              _.get(val, "score.hfgs_normalized", 0)
            );
            this.hand_feel_data.warmth = this.number_fixed(
              _.get(val, "score.hfgw_normalized", 0)
            );
            this.hand_feel_data.total = this.number_fixed(
              _.get(val, "score.thf_normalized", 0)
            );

            this.hand_feel_score.roughness = this.number_fixed(
              _.get(val, "avg_score.hfgr_normalized", 0)
            );
            this.hand_feel_score.softness = this.number_fixed(
              _.get(val, "avg_score.hfgs_normalized", 0)
            );
            this.hand_feel_score.warmth = this.number_fixed(
              _.get(val, "avg_score.hfgw_normalized", 0)
            );
            this.hand_feel_score.total = this.number_fixed(
              _.get(val, "avg_score.thf_normalized", 0)
            );
            this.$nextTick(() => {
              this.initChart();
            });
          } else {
            this.handFabrics = _.get(val, "all_fabrics_hand", []);
            this.currentHandFabric = _.get(val, "all_fabrics_hand.0", {});
            this.getFabricScore()
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
<style scoped lang="scss">
.bar-title {
  width: max-content;
  margin-top: 1.25rem;
}
.chart-box {
  margin: 0 1rem;
  width: calc(100vw - 1.5rem);
  height: calc(100vw - 1rem);
 /*height: calc((100vw - 2rem) * 0.72);*/
}
.chart-options {
  position: absolute;
  top: 3px;
  right: 0;
}
</style>
