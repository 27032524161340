<template>
  <div>
    <div class="hand_chart position-relative">
      <div :id="skinChartId" class="chart-box"></div>
      <div class="chart-options d-flex align-items-center">
        <div>{{$t('fabric.id')}}:</div>
        <div>
          <div class="btn-group" role="group">
            <button id="btnGroupVerticalDrop1" type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="padding: 0 3px;">
              {{ currentSkinFabric.fabric_id }}
            </button>
            <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
              <span v-for="(hItem,hIndex) in skinFabrics" :key="hIndex" class="dropdown-item" @click="setCurrentSkinFabric(hItem)">{{hItem.fabric_id}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bars p-4 pt-0 ps-3">
      <div class="py-1">
        <table>
          <tr>
            <td>
              <div class="bar-title">{{ $t('radar_chart.prickle') }}：</div>
            </td>
            <td class="w-100 py-5">
              <feel-tip-bar bar-type="skin_roughness" :value="skin_feel_data.prickle"></feel-tip-bar>
            </td>
          </tr>
          <tr>
            <td>
              <div class="bar-title">{{ $t('radar_chart.softness') }}：</div>
            </td>
            <td class="w-100 py-5">
              <feel-tip-bar bar-type="skin_softness" :value="skin_feel_data.softness"></feel-tip-bar>
            </td>
          </tr>
          <tr>
            <td>
              <div class="bar-title">{{ $t('radar_chart.warmth') }}：</div>
            </td>
            <td class="w-100 py-5">
              <feel-tip-bar bar-type="skin_warmth" :value="skin_feel_data.warmth"></feel-tip-bar>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {radarChart} from "@/utils/chart";
import _ from "lodash";
import FeelTipBar from "@/components/feel-tip-bar.vue";

export default {
  components: {FeelTipBar},
  props: {
    detailForm: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      skinChartId: `skin${+new Date()}`,
      skin_feel_star: {
        roughness: "",
        softness: "",
        warmth: "",
        total: "",
      },
      skin_feel_data: {
        prickle: "",
        softness: "",
        warmth: "",
        total: "",
      },
      skin_feel_score: {
        prickle: "",
        softness: "",
        warmth: "",
        total: "",
      },
      name: "",
      currentSkinFabric: {},
      skinFabrics: [],
    };
  },
  methods: {
    initChart() {
      this.skin_feel_chart();
    },
    skin_feel_chart() {
      let options = {
        data: [
          this.get_number_default(this.skin_feel_data.softness),
          this.get_number_default(this.skin_feel_data.prickle),
          this.get_number_default(this.skin_feel_data.warmth),
          this.get_number_default(this.skin_feel_data.total),
        ],
        ave: [
          this.get_number_default(this.skin_feel_score.softness),
          this.get_number_default(this.skin_feel_score.prickle),
          this.get_number_default(this.skin_feel_score.warmth),
          this.get_number_default(this.skin_feel_score.total),
        ],
        color: "#13C56B",
        name: this.name,
        chartDom: document.querySelector(`#${this.skinChartId}`),
      };
      radarChart(options, 'mobile', 'skin');
    },
    number_fixed(data) {
      if (data == "") {
        return data;
      }
      return parseFloat(data.toFixed(2));
    },
    get_number_default(data) {
      if (data == "") {
        return 0;
      }
      return data;
    },
    // 获取当前面料的手感肤感评分
    getFabricScore() {
      this.skin_feel_star.prickle = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort_value.skin_smooth", "")
      );
      this.skin_feel_star.softness = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort_value.skin_stiff", "")
      );
      this.skin_feel_star.warmth = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort_value.skin_cool", "")
      );
      this.skin_feel_star.total = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort_value.skin_total", "")
      );

      this.skin_feel_data.prickle = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort.skin_smooth", 0)
      );
      this.skin_feel_data.softness = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort.skin_stiff", 0)
      );
      this.skin_feel_data.warmth = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort.skin_cool", 0)
      );
      this.skin_feel_data.total = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort.skin_total", 0)
      );

      this.skin_feel_score.prickle = this.number_fixed(
          _.get(this.currentSkinFabric, "avg_score.skin_smooth", 0)
      );
      this.skin_feel_score.softness = this.number_fixed(
          _.get(this.currentSkinFabric, "avg_score.skin_stiff", 0)
      );
      this.skin_feel_score.warmth = this.number_fixed(
          _.get(this.currentSkinFabric, "avg_score.skin_cool", 0)
      );
      this.skin_feel_score.total = this.number_fixed(
          _.get(this.currentSkinFabric, "avg_score.skin_total", 0)
      );

      this.$nextTick(() => {
        this.initChart();
      });
    },
    setCurrentSkinFabric(item) {
      this.currentSkinFabric = item;
      this.getFabricScore()
    },
  },
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          this.name = _.get(val, "name", "");
          if (this.type === 'fabric') {
            this.skin_feel_star.prickle = this.number_fixed(
              _.get(val, "skin_comfort_garment_prickle", "")
            );
            this.skin_feel_star.softness = this.number_fixed(
              _.get(val, "skin_comfort_garment_softness", "")
            );
            this.skin_feel_star.warmth = this.number_fixed(
              _.get(val, "skin_comfort_garment_warmth", "")
            );
            this.skin_feel_star.total = this.number_fixed(
              _.get(val, "total_skin_feel", "")
            );

            this.skin_feel_data.prickle = this.number_fixed(
              _.get(val, "score.scgp_normalized", 0)
            );
            this.skin_feel_data.softness = this.number_fixed(
              _.get(val, "score.scgs_normalized", 0)
            );
            this.skin_feel_data.warmth = this.number_fixed(
              _.get(val, "score.scgw_normalized", 0)
            );
            this.skin_feel_data.total = this.number_fixed(
              _.get(val, "score.tsf_normalized", 0)
            );

            this.skin_feel_score.prickle = this.number_fixed(
              _.get(val, "avg_score.scgp_normalized", 0)
            );
            this.skin_feel_score.softness = this.number_fixed(
              _.get(val, "avg_score.scgs_normalized", 0)
            );
            this.skin_feel_score.warmth = this.number_fixed(
              _.get(val, "avg_score.scgw_normalized", 0)
            );
            this.skin_feel_score.total = this.number_fixed(
              _.get(val, "avg_score.tsf_normalized", 0)
            );
            this.$nextTick(() => {
              this.initChart();
            });
          } else {
            this.skinFabrics = _.get(val, "all_fabrics_skin", []);
            this.currentSkinFabric = _.get(val, "all_fabrics_skin.0", {});
            this.getFabricScore()
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
<style scoped lang="scss">
.bar-title {
  width: max-content;
  margin-top: 1.25rem;
}
.chart-box {
  margin: 0 1rem;
  width: calc(100vw - 2rem);
 /* height: calc((100vw - 2rem) * 0.72);*/
  height:  calc(100vw - 1rem);
}
.chart-options {
  position: absolute;
  top: 3px;
  right: 0;
}
</style>
